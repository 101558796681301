import axios from 'axios';

import i18n from '../i18n';
import { getTranslation } from '../methods/common';

export const auctionIsInitialized = (auction) => auction && auction.portalInfo;

export const auctionIsPaused = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'PAUSED';

export const auctionIsPending = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'PENDING';

export const auctionIsLive = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'LIVE';

export const auctionIsClosed = (auction) => auction && auction.portalInfo && auction.portalInfo.status === 'CLOSED';

export const lotIsPending = (lot) => lot && lot.portalInfo && lot.portalInfo.status === 'PENDING';

export const lotIsLive = (lot) => lot && lot.portalInfo && ['LIVE', 'ONE', 'TWO', 'THREE'].includes(lot.portalInfo.status);

export const lotIsHammering = (lot) => lot && lot.portalInfo && ['ONE', 'TWO', 'THREE'].includes(lot.portalInfo.status);

export const lotIsClosed = (lot) => lot && lot.portalInfo && lot.portalInfo.status === 'CLOSED';

export const lotIsSold = (lot) => lotIsClosed(lot) && getLotCurrentBid(lot) > 0 && lot.portalInfo.result === 'SOLD';

export const lotIsReserved = (lot) => lotIsClosed(lot) && getLotCurrentBid(lot) > 0 && lot.portalInfo.result === 'RESERVATION';

export const lotIsUnsold = (lot) => lotIsClosed(lot) && lot && lot.portalInfo && lot.portalInfo.result === 'UNSOLD';

export const lotCanHammer = (auction, lot) => auctionIsLive(auction) && lotIsLive(lot) && getLotCurrentBid(lot) > 0;

export const lotCanCloseSold = (auction, lot) => auctionIsLive(auction) && lot && lot.portalInfo && lot.portalInfo.status === 'THREE';

export const lotCanOpen = (auction, lot) => auctionIsLive(auction) && (lotIsPending(lot) || lotIsClosed(lot));

export const lotCannotSwitch = (auction, lot) => !auctionIsLive(auction) || lotIsLive(lot);

export const lotCannotReceiveBids = (lot) => !lotIsLive(lot);

export const lotCannotRevertBid = (lot) => !lotIsLive(lot) || !getLotCurrentBid(lot);

export const lotCannotLastCall = (lot) => !lotIsLive(lot);

export const lotCannotClose = (lot) => !lotIsLive(lot) || !getLotCurrentBid(lot);

export const lotCannotCloseUnsold = (lot) => !lotIsLive(lot);

export const lotCannotReset = (lot) => !lotIsLive(lot) || !getLotCurrentBid(lot);

export const lotCurrentHolderIsRoom = (lot) => !!(lot?.portalInfo?.currentHolderIsAdmin);

export const getAuctionStatus = (auction, t) => {
  if (auctionIsInitialized(auction) && auction.portalInfo.status) {
    return t(`portal.auctionStatus.${auction.portalInfo.status}`);
  }
  return '';
};

export const getLotId = (lot) => lot && lot.portalInfo && lot.portalInfo._id;

export const getLotResult = (lot, t) => {
  if (lot && lot.portalInfo && lot.portalInfo.result) {
    return t(`portal.lotResult.${lot.portalInfo.result}`);
  }
  return '';
};

export const getLotStatus = (lot, t) => {
  if (!lot || !lot.portalInfo || (!lot.portalInfo.status)) {
    return t('portal.lotStatus.PENDING');
  }
  return t(`portal.lotStatus.${lot.portalInfo.status}`);
};

export const getLotAdvanceBids = (lot) => {
  const bids = lot?.portalInfo?.advanceBids || [];
  return bids.filter((b) => b.amount > 0 && !b.phone && !b.retractedAt).sort((a, b) => b.amount - a.amount);
};

export const getLotMaxAdvanceBids = (lot) => {
  const bids = getLotAdvanceBids(lot);
  if (!bids.length) return bids;
  const max = bids[0].amount;
  return bids.filter((b) => b.amount === max);
};

export const getLotPhoneBids = (lot) => {
  const bids = lot?.portalInfo?.advanceBids || [];
  return bids.filter((b) => !!b.phone && !b.retractedAt);
};

export const getProtocolEntry = (entry, t) => {
  switch (entry.type) {
    case 'advance-bid-accepted':
      return t(
        `portal.protocol.advance-bid-accepted`,
        { ...entry, value: i18n.currency(entry.bid.newAmount, { decimals: 0, withSymbol: true }) },
      );
    case 'bid':
      if (entry.user?.name) {
        return t(
          `portal.protocol.${entry.type}`, {
            ...entry,
            name: entry.user.name,
            value: i18n.currency(entry.bid.newAmount, { decimals: 0, withSymbol: true }),
          },
        );
      }
      return t(
        `portal.protocol.anonymousBid`,
        { ...entry, value: i18n.currency(entry.bid.newAmount, { decimals: 0, withSymbol: true }) },
      );
    case 'increment':
      return t(
        `portal.protocol.${entry.type}`,
        { ...entry, value: i18n.currency(entry.increment, { decimals: 0, withSymbol: true }),
        });
    default:
      return t(`portal.protocol.${entry.type}`, entry);
  }
};

export const getProtocolTime = (entry, t) => {
  return t('portal.protocol.date', { date: new Date(entry.date) });
};

const getLotLimit = (lot) => {
  if (lot && lot.portalInfo && lot.portalInfo.limit) {
    return lot.portalInfo.limit;
  }
  if (lot && lot.limit) {
    return lot.limit;
  }
  return 0;
};

const getAuctionIncrements = (auction) => {
  if (auction && auction.portalInfo && auction.portalInfo.increments) {
    return auction.portalInfo.increments;
  }
  return [{ from: 0, value: 50_00 }];
};

export const getLotCurrentBid = (lot) => {
  if (lot && lot.portalInfo && lot.portalInfo.currentBid) {
    return lot.portalInfo.currentBid;
  }
  return null;
};

export const getLotCurrentAsk = (auction, lot) => {
  return nextIncremental(auction, lot);
}

export const getLotCurrentAskFormatted = (auction, lot) => {
  return i18n.currency(
    getLotCurrentAsk(auction, lot),
    { decimals: 0, withSymbol: true },
  );
};

export const getLotCurrentBidFormatted = (lot) => {
  if (getLotCurrentBid(lot)) {
    return i18n.currency(
      getLotCurrentBid(lot),
      { decimals: 0, withSymbol: true },
    );
  }
  return '—';
};

export const getLotNextBidFormatted = (lot) => {
  const nextBid = lot.portalInfo.currentBid
    ? lot.portalInfo.currentBid + lot.portalInfo.increment
    : lot.portalInfo.limit;
  return i18n.currency(
    nextBid,
    { decimals: 0, withSymbol: true },
  );
};

const _nextIncremental = (increments, limit, currentBid) => {
  if (!currentBid) {
    return limit;
  }
  let lastFrom = 0;
  let lastIncrement = 1;
  for (let i = 0; i < increments.length; ++i) {
    if (currentBid < increments[i].from) {
      break;
    }
    lastFrom = increments[i].from;
    lastIncrement = increments[i].value;
  }
  const offset = (currentBid - lastFrom) % lastIncrement;
  return currentBid - offset + lastIncrement;
};

export const nextIncremental = (auction, lot) => {
  const increments = getAuctionIncrements(auction);
  const limit = getLotLimit(lot);
  const currentBid = getLotCurrentBid(lot);
  return _nextIncremental(increments, limit, currentBid);
};

export const nextIncrementals = (auction, lot, n) => {
  const increments = getAuctionIncrements(auction);
  const limit = getLotLimit(lot);
  let currentBid = getLotCurrentBid(lot);
  const incrementals = [];
  for (let i = 0; i < n; ++i) {
    currentBid = _nextIncremental(increments, limit, currentBid);
    incrementals.push(currentBid);
  }
  return incrementals;
};

export const getLotAdvanceBidAmount = (auction, lot, advanceBid) => {
  const advanceBids = getLotAdvanceBids(lot);
  if (advanceBids.length < 2) {
    return Math.min(advanceBid.amount, nextIncremental(auction, lot));
  }
  advanceBids.sort((a, b) => b.amount - a.amount);
  return Math.min(
    advanceBid.amount,
    _nextIncremental(getAuctionIncrements(auction), getLotLimit(lot), Math.max(getLotCurrentBid(lot), advanceBids[1].amount)),
  );
};

export const canPlaceAdvanceBid = (auction) => {
  return !auction?.date || ((new Date(auction.date) - new Date()) > (3 * 60 * 60 * 1000));
};

export const isUnderLimit = (amount, lot) => {
  return amount && lot?.portalInfo?.limit && amount < lot.portalInfo.limit;
};

export const canPlaceAftersaleBid = (auction) => {
  return auction?.afterSale && auction?.date && new Date(auction.date) < new Date();
};

export const getMessageText = (msg, messages) => {
  if (!msg) return '';
  let text = msg.text;
  if (msg.type === 'FAIRWARNING') {
    text = i18n.t('portal.fairWarning');
  }
  if (msg.type === 'TEMPLATE') {
    const msgTemplate = messages.find((m) => m._id === msg.text);
    text = getTranslation(msgTemplate, 'messageText');
  }
  return text;
};

export const getUserName = (user) => (user && user.profile && user.profile.name) || '';

export const userIsAuctioneer = (user) => user && user.profile && user.profile.claims
  && user.profile.claims.includes('auctioneer');

export const userIsHolder = (user, lot) => lot && lot.portalInfo && user && user.profile && lot.portalInfo.currentHolder === user.profile.sub;

export const sendLogout = async (user) => {
  return axios.post(
    `${process.env.REACT_APP_OIDC_AUTHORITY}/oidc/logout`,
    {},
    { headers: { Authorization: `Bearer ${user.access_token}` } },
  );
};

export const hasAdvanceBid = (lot, userId) => {
  return lot?.portalInfo?.advanceBids?.some((b) => b.userId === userId && !b.retractedAt);
};

export const getAdvanceBid = (lot, userId) => {
  return lot?.portalInfo?.advanceBids?.find((b) => b.userId === userId && !b.retractedAt);
};

export const hasAftersaleBid = (lot, userId) => {
  return lot?.portalInfo?.aftersaleBids?.some((b) => b.userId === userId && !b.retractedAt);
};

export const getAftersaleBid = (lot, userId) => {
  return lot?.portalInfo?.aftersaleBids?.find((b) => b.userId === userId && !b.retractedAt);
};
