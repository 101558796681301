import { useLazyQuery, useSubscription } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

import { useConfig } from './AppProvider';
import { useCache } from './CacheProvider';
import { usePortal } from './PortalProvider';
import { getMessageText } from '../modules/portal';
import {
  MESSAGES_SUBSCRIPTION,
  MESSAGES_QUERY,
} from '../schema/portal';

const PortalMessageContext = React.createContext(null);

const usePortalMessages = () => {
  const context = React.useContext(PortalMessageContext);

  return context;
};

const addRef = (message) => (message ? {
  ...message,
  ref: React.createRef(),
} : null);

const PortalMessageProvider = ({ children = null }) => {
  const cache = useCache();
  const { toast } = useConfig();
  const { auction } = usePortal();
  const [messageTemplates, setMessageTemplates] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [currentMessage, setCurrentMessage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);

  const auctionNumber = auction.number || '';
  const [getMessages, { data: messagesData, loading: messagesLoading, refetch }] = useLazyQuery(
    MESSAGES_QUERY,
    { skip: !auction.number },
  );
  const { data: messageSub, loading: messageLoading } = useSubscription(
    MESSAGES_SUBSCRIPTION,
    { variables: { auctionNumber }, skip: !auction.number },
  );

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setMessageTemplates, setLoading, 'portal-messages', null, controller);
    return () => controller.abort();
  }, []);

  React.useEffect(() => {
    if (auctionNumber) {
      const controller = new AbortController();
      getMessages({ variables: { auctionNumber } });
      return () => controller.abort();
    }
  }, [auctionNumber]);

  React.useEffect(() => {
    if (!messagesLoading && messagesData?.messages) {
      setMessages(messagesData.messages.map(addRef));
    }
  }, [messagesData?.messages, messagesLoading]);

  React.useEffect(() => {
    setMessages(messages => messages.filter((msg) => msg?.type !== 'FAIRWARNING'));
  }, [auction?.portalInfo?.currentLotId]);

  React.useEffect(() => {
    if (!messageLoading && messageSub?.messageReceived) {
      const controller = new AbortController();
      const msg = messageSub.messageReceived;
      if (msg.deletedAt) {
        setMessages((prev) => prev.filter((m) => m._id !== msg._id));
      } else {
        setMessages((prev) => [addRef(msg), ...prev]);
        setCurrentMessage(1);
        toast.set({
          message: getMessageText(msg, messages),
          type: msg.type === 'FASTER' ? 'warning' : 'info',
        });
      }
      return () => controller.abort();
    }
  }, [!messageLoading && messageSub?.messageReceived]);

  // React.useEffect(() => {
  //   if (messages?.length > 0) {
  //     const interval = setInterval(() => {
  //       setCurrentMessage((prev) => (prev < messages.length ? prev + 1 : 1));
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }
  // }, [messages]);

  React.useEffect(() => {
    if (messages?.length > 0) {
      messages[currentMessage - 1]?.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [currentMessage, messages]);

  const anyLoading = loading || messagesLoading || messageLoading;

  return (
    <PortalMessageContext.Provider value={{
      loading: anyLoading,
      currentMessage,
      messages,
      messageTemplates,
      refetchMessages: refetch,
      setCurrentMessage,
    }}>
      {children}
    </PortalMessageContext.Provider>
  );
};

PortalMessageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { PortalMessageProvider, usePortalMessages };
