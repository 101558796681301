import { gql } from '@apollo/client';

export const AUCTION_QUERY = gql`
  query AuctionQuery($auctionNumber: String!) {
    auction(number: $auctionNumber) {
      currentLotId
      increments {
        from
        value
      }
      name
      number
      status
    }
    advanceBids(auctionNumber: $auctionNumber) {
      _id
      lotId
      userId
      amount
      date
      phone
      name
      retractedAt
    }
    lots(auctionNumber: $auctionNumber) {
      _id
      currentBid
      currentHolder
      currentHolderName
      currentHolderIsAdmin
      increment
      limit
      name
      number
      order
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
        user {
          name
        }
      }
      reference
      result
      status
    }
  }
`;

export const AUCTION_SUBSCRIPTION = gql`
  subscription AuctionSubscription($number: String!) {
    auctionChanged(number: $number) {
      _id
      currentLotId
      increments {
        from
        value
      }
      name
      number
      status
    }
  }
`;

export const LOTS_SUBSCRIPTION = gql`
  subscription LotsSubscription($auctionNumber: String!) {
    lotChanged(auctionNumber: $auctionNumber) {
      _id
      auctionId
      currentBid
      currentHolder
      currentHolderName
      currentHolderIsAdmin
      increment
      limit
      name
      number
      order
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
        user {
          name
        }
      }
      reference
      result
      status
    }
  }
`;

export const ADVANCE_BIDS_SUBSCRIPTION = gql`
  subscription AdvanceBidsSubscription($auctionNumber: String!) {
    advanceBidChanged(auctionNumber: $auctionNumber) {
      _id
      auctionId
      lotId
      userId
      amount
      date
      phone
      name
      retractedAt
    }
  }
`;

export const auctionSubscriptionHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  if (!subscriptionData.data.auctionChanged) return prev;
  const auction = subscriptionData.data.auctionChanged;
  return {
    ...prev,
    auction,
  };
};

export const lotsSubscriptionHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  if (!subscriptionData.data.lotChanged) return prev;
  const lot = subscriptionData.data.lotChanged;
  if (prev.lots.find(l => l._id === lot._id)) {
    return {
      ...prev,
      lots: prev.lots.map(l => l._id === lot._id ? lot : l),
    };
  }
  return {
    ...prev,
    lots: [...prev.lots, lot],
  };
};

export const advanceBidsSubscriptionHandler = (prev, { subscriptionData }) => {
  if (!subscriptionData.data) return prev;
  if (!subscriptionData.data.advanceBidChanged) return prev;
  const advanceBid = subscriptionData.data.advanceBidChanged;
  if (prev.advanceBids.find(b => b._id === advanceBid._id)) {
    return {
      ...prev,
      advanceBids: prev.advanceBids.map(b => b._id === advanceBid._id ? advanceBid : b),
    };
  }
  return {
    ...prev,
    advanceBids: [...prev.advanceBids, advanceBid],
  };
};

export const MESSAGES_QUERY = gql`
  query MessagesQuery($auctionNumber: String!) {
    messages(auctionNumber: $auctionNumber) {
      _id
      date
      deletedAt
      text
      type
    }
  }
`;

export const MESSAGES_SUBSCRIPTION = gql`
  subscription MessagesSubscription($auctionNumber: String!) {
    messageReceived(auctionNumber: $auctionNumber) {
      _id
      date
      deletedAt
      text
      type
    }
  }
`;

export const OPEN_AUCTION = gql`
  mutation OpenAuction($number: String!) {
    openAuction(number: $number) {
      _id
      status
    }
  }
`;

export const CLOSE_AUCTION = gql`
  mutation CloseAuction($number: String!) {
    closeAuction(number: $number) {
      _id
      status
    }
  }
`;

export const PAUSE_AUCTION = gql`
  mutation PauseAuction($number: String!) {
    pauseAuction(number: $number) {
      _id
      status
    }
  }
`;

export const RESUME_AUCTION = gql`
  mutation ResumeAuction($number: String!) {
    resumeAuction(number: $number) {
      _id
      status
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation PostMessage($auctionNumber: String!, $message: MessageInput!) {
    postMessage(auctionNumber: $auctionNumber, message: $message) {
      _id
      type
      text
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($messageId: ID!) {
    deleteMessage(messageId: $messageId) {
      _id
    }
  }
`;

export const NEXT_LOT = gql`
  mutation NextLot($number: String!) {
    nextLot(number: $number) {
      _id
    }
  }
`;

export const PREVIOUS_LOT = gql`
  mutation PreviousLot($number: String!) {
    previousLot(number: $number) {
      _id
    }
  }
`;

export const OPEN_LOT = gql`
  mutation OpenLot($lotId: ID!, $live: Boolean) {
    openLot(lotId: $lotId, live: $live) {
      _id
      increment
      status
    }
  }
`;

export const HAMMER_LOT = gql`
  mutation HammerLot($lotId: ID!) {
    hammerLot(lotId: $lotId) {
      _id
      status
    }
  }
`;

export const CLOSE_LOT = gql`
  mutation CloseLot($lotId: ID!, $result: LotResult) {
    closeLot(lotId: $lotId, result: $result) {
      _id
      result
      status
    }
  }
`;

export const RESET_LOT = gql`
  mutation ResetLot($lotId: ID!) {
    resetLot(lotId: $lotId) {
      _id
      currentBid
      currentHolder
      increment
      status
    }
  }
`;

export const SET_INCREMENT = gql`
  mutation SetIncrement($lotId: ID!, $increment: Int!) {
    setIncrement(lotId: $lotId, increment: $increment) {
      _id
      increment
    }
  }
`;

export const PLACE_BID = gql`
  mutation PlaceBid($bid: BidInput) {
    placeBid(bid: $bid) {
      _id
      currentBid
      currentHolder
      increment
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
        user {
          name
        }
      }
    }
  }
`;

export const REVERT_BID = gql`
  mutation RevertBid($revert: RevertInput) {
    revertBid(revert: $revert) {
      _id
      currentBid
      currentHolder
      increment
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
        user {
          name
        }
      }
    }
  }
`;

export const ACCEPT_ADVANCE_BID = gql`
  mutation AcceptAdvanceBid($advanceBidId: ID!, $customAmount: Int) {
    acceptAdvanceBid(advanceBidId: $advanceBidId, customAmount: $customAmount) {
      _id
      currentBid
      currentHolder
      increment
      protocol {
        date
        type
        bid {
          newAmount
          oldAmount
        }
        increment
        user {
          name
        }
      }
    }
  }
`;

export const PLACE_ADVANCE_BID = gql`
  mutation PlaceAdvanceBid($advanceBid: AdvanceBidInput!) {
    placeAdvanceBid(advanceBid: $advanceBid) {
      _id
      auctionId
      lotId
      userId
      amount
      date
      phone
      name
      retractedAt
    }
  }
`;

export const RETRACT_ADVANCE_BID = gql`
  mutation RetractAdvanceBid($advanceBidId: ID!) {
    retractAdvanceBid(advanceBidId: $advanceBidId) {
      _id
      auctionId
      lotId
      userId
      amount
      date
      phone
      name
      retractedAt
    }
  }
`;
