import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React from 'react';

import { useConfig } from '../hooks/AppProvider';

const MessageToast = () => {
  const duration = 6000;
  const { toast } = useConfig();
  const [open, setOpen] = React.useState(false);

  const { message, type = 'info' } = toast.get;

  React.useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [toast]);

  const handleClose = () => {
    setOpen(false);
    toast.set({});
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={duration}
      onClose={handleClose}
      message={message}
      action={action}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MessageToast;