import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Warning from '../components/Warning';

const NoMatch = () => {
  const { t } = useTranslation();

  return (
    <Warning>
      <Typography variant="h1">
        {t('general.pageNotFoundTitle')}
      </Typography>
      <Typography variant="h2">
        {t('general.pageNotFound')}
      </Typography>
    </Warning>
  );
};

export default NoMatch;
