import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConfig } from './AppProvider';
import { useAuctions } from './AuctionProvider';
import { usePortal } from './PortalProvider';

const NavigationContext = React.createContext('');

const useNav = () => {
  const navigation = React.useContext(NavigationContext);

  return navigation;
};

// eslint-disable-next-line react/display-name
const withNav = (Component) => (props) => {
  const nav = useNav();

  return <Component {...props} nav={nav} />;
};

const NavProvider = ({ children = null }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { activeAuction } = useAuctions();
  const { config } = useConfig();
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { adminView } = usePortal();
  const lang = i18n.getLang();

  const toggleDrawer = React.useCallback(() => {
    setDrawerOpen(open => !open);
  }, []);

  const applyFilter = (routes, filter) => {
    return routes.filter(filter).map(route => (route.children ? ({
      ...route,
      children: applyFilter(route.children, filter),
    }) : route));
  }

  const menu = React.useMemo(() => {
    const routes = config.routes || [];
    return applyFilter(routes, (route) => {
      if (route.afterSale) return activeAuction?.afterSale;
      return true;
    });
  }, [activeAuction, config]);

  const getMatches = (partial) => {
    const matches = [];
    const pathparts = location.pathname.split('/').filter((p) => p);
    let haystack = menu;
    while(pathparts.length) {
      const part = pathparts[0];
      const node = haystack?.find((link) => link.data?.find((translation) => translation.alias === part) || link.alias === part);
      if (!node) return partial && matches;
      haystack = node.children;
      matches.push(node);
      pathparts.shift();
    }
    return matches;
  }

  const getNodeLangs = (node, pathpart) => node.data?.filter((translation) => translation.alias === pathpart).map((translation) => translation.lang) || [];

  React.useEffect(() => {
    const matches = getMatches(true);
    const pathparts = location.pathname.split('/').filter((p) => p);
    let matchingLangs = i18n.supportedLocales;
    if (!matches) return;
    for (let i = matches.length - 1; i >= 0; i = i - 1) {
      const nodeLangs = getNodeLangs(matches[i], pathparts[i]);
      matchingLangs = matchingLangs.filter((l) => nodeLangs.includes(l));
    }
    if (matchingLangs.length && !matchingLangs.includes(i18n.getLang())) {
      i18n.changeLanguage(matchingLangs[0]);
    }
  }, [menu]);

  // handle language change
  // update url alias
  React.useEffect(() => {
    const matches = getMatches();
    if (!matches) return;
    const url = matches.map((link) => (link.data.find((l) => l.lang === lang) || link).alias).filter((m) => m);
    const newPath = `/${url.join('/')}${location.search}`;
    if (newPath === location.pathname) return;
    if (url.length !== matches.length) {
      navigate('/');
      return;
    }
    navigate(newPath);
  }, [lang, menu]);

  const portalAdmin = React.useMemo(() => {
    return location.pathname.startsWith('/portal') && adminView;
  }, [location.pathname, adminView]);

  return (
    <NavigationContext.Provider value={{ drawerOpen, getMatches, menu, portalAdmin, toggleDrawer }}>
      {children}
    </NavigationContext.Provider>
  );
};

NavProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { NavProvider, useNav, withNav };
