import { motion } from 'framer-motion';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const StyledBox = motion(styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
  '& > *': { 'textAlign': 'center' },
  h1: {
    textShadow: '2px 2px 5px rgba(0 0 0 / 0.1)',
    fontSize: '2rem',
    margin: '3rem 0 0.5rem 0',
    lineHeight: 1.3,
    color: theme.palette.text.primary,
  },
  h2: {
    padding: '0 2rem',
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    h1: {
      marginTop: '6rem',
      fontSize: '3.5rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
  },
})));

const trianglePoints = (r) => {
  const height = r * Math.sqrt(3);
  return `0,${height} ${r},0 ${r * 2},${height}`;
};

const Warning = ({ children = null }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const height = matches ? '40rem' : '24rem';
  const triangleSize = matches ? 240 : 140;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={height}
    >
      <Box position="relative">
        <motion.svg
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ duration: 1, type: 'spring', stiffness: 120 }}
          width={`${triangleSize * 2}px`}
          height={`${triangleSize * Math.sqrt(3)}px`}
        >
          <polygon points={trianglePoints(triangleSize)} fill="#F6EAD8" />
        </motion.svg>
        <StyledBox
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 1, type: 'spring', stiffness: 120 }}
        >
          {children}
        </StyledBox>
      </Box>
    </Box>
  );
};

Warning.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Warning;