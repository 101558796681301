import { useLazyQuery, useMutation } from '@apollo/client';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import PropTypes from 'prop-types';

import { useCache } from './CacheProvider';
import { getLotCurrentBid, userIsAuctioneer } from '../modules/portal';
import {
  ADVANCE_BIDS_SUBSCRIPTION,
  advanceBidsSubscriptionHandler,
  AUCTION_QUERY,
  AUCTION_SUBSCRIPTION,
  auctionSubscriptionHandler,
  LOTS_SUBSCRIPTION,
  lotsSubscriptionHandler,
  HAMMER_LOT,
} from '../schema/portal';

const PortalContext = React.createContext(null);

const usePortal = () => {
  const context = React.useContext(PortalContext);

  return context;
};

const PortalProvider = ({ children = null }) => {
  const { user } = useAuth();
  const cache = useCache();
  const animationRef = React.useRef(null);
  const [hammerAnimationRunning, setHammerAnimationRunning] = React.useState(false);

  const [adminView, setAdminView] = React.useState(userIsAuctioneer(user));
  const [auctions, setAuctions] = React.useState([]);
  const [lots, setLots] = React.useState([]);
  const [portalLoading, setPortalLoading] = React.useState(true);
  const [lotsLoading, setLotsLoading] = React.useState(true);

  const auction = auctions?.[0] || {};
  const auctionNumber = auction.number || '';
  const userId = user?.profile?.sub || null;

  const [getAuction, { data, error, loading, subscribeToMore }] = useLazyQuery(AUCTION_QUERY, { skip: !auctionNumber });
  const [hammerLot] = useMutation(HAMMER_LOT);

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setAuctions, setPortalLoading, 'portal', null, controller);
    return () => controller.abort();
  }, []);

  React.useEffect(() => {
    if (auctionNumber) {
      const controller = new AbortController();
      cache.fetchData(setLots, setLotsLoading, `lots/${auctionNumber}`, null, controller);
      getAuction({ variables: { auctionNumber } })
        .then(() => {
          subscribeToMore({
            document: AUCTION_SUBSCRIPTION,
            variables: { number: auctionNumber },
            updateQuery: auctionSubscriptionHandler,
          });
          subscribeToMore({
            document: LOTS_SUBSCRIPTION,
            variables: { auctionNumber },
            updateQuery: lotsSubscriptionHandler,
          });
          subscribeToMore({
            document: ADVANCE_BIDS_SUBSCRIPTION,
            variables: { auctionNumber },
            updateQuery: advanceBidsSubscriptionHandler,
          });
        });
      return () => controller.abort();
    }
  }, [auctionNumber, subscribeToMore]);

  const combinedAuction = React.useMemo(() => ({
    ...auction,
    portalInfo: data && data.auction,
  }), [auction, data && data.auction]);

  const addAdvanceBids = (pLot, bids) => {
    if (!pLot) return pLot;
    return {
      ...pLot,
      advanceBids: bids.filter(b => b.lotId === pLot._id),
    };
  };

  const portalList = React.useMemo(() => {
    if (!(data && data.advanceBids && data.lots && lots)) return [];
    return lots
      .map(l => ({
        ...l,
        portalInfo: addAdvanceBids(data.lots.find(pl => pl.reference === l._id), data.advanceBids),
        ref: React.createRef(),
      }))
      .filter(l => !!l.portalInfo);
  }, [data && data.advanceBids, data && data.lots, lots]);

  const activeLot = React.useMemo(() => {
    const portalActiveLotId = data && data.auction && data.auction.currentLotId;
    return portalList.find(l => l.portalInfo._id === portalActiveLotId);
  }, [data, portalList]);

  const startHammering = () => {
    setHammerAnimationRunning(true);
    animationRef.current = window.setInterval(() => {
      hammerLot({
        variables: { lotId: activeLot.portalInfo._id },
        onCompleted: (lot) => {
          if (lot.hammerLot.status === 'THREE' || lot.hammerLot.status === 'CLOSED') {
            stopHammering();
          }
        }
      });
    }, 1500);
  };

  const stopHammering = () => {
    clearInterval(animationRef.current);
    animationRef.current = null;
    setHammerAnimationRunning(false);
  };

  React.useEffect(() => {
    if (animationRef.current) {
      stopHammering();
    }
  }, [getLotCurrentBid(activeLot)]);

  React.useEffect(() => {
    if (userIsAuctioneer(user)) {
      setAdminView(true);
    } else {
      setAdminView(false);
    }
  }, [user?.profile?.sub]);

  const anyLoading = portalLoading || lotsLoading || loading;

  return (
    <PortalContext.Provider value={{
      activeLot,
      adminView,
      hammerAnimationRunning,
      auction: combinedAuction,
      error,
      loading: anyLoading,
      lots: portalList,
      setAdminView,
      startHammering,
      userId,
    }}>
      {children}
    </PortalContext.Provider>
  );
};

PortalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { PortalProvider, usePortal };
