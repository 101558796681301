import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './assets/theme';
import ApolloProvider from './hooks/ApolloProvider';
import { AppProvider } from './hooks/AppProvider';
import { AuctionProvider } from './hooks/AuctionProvider';
import { CacheProvider } from './hooks/CacheProvider';
import { CookieProvider } from './hooks/CookieProvider';
import { NavProvider } from './hooks/NavProvider';
import OAuthProvider from './hooks/OAuthProvider';
import { PortalProvider } from './hooks/PortalProvider';
import { UserProvider } from './hooks/UserProvider';
import './i18n';
import { PortalMessageProvider } from './hooks/PortalMessageProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <OAuthProvider>
      <ApolloProvider>
        <CacheProvider>
          <AppProvider>
            <AuctionProvider>
              <PortalProvider>
                <PortalMessageProvider>
                  <CookieProvider>
                    <HelmetProvider>
                        <UserProvider>
                          <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Router>
                              <NavProvider>
                                <App />
                              </NavProvider>
                            </Router>
                          </ThemeProvider>
                        </UserProvider>
                    </HelmetProvider>
                  </CookieProvider>
                </PortalMessageProvider>
              </PortalProvider>
            </AuctionProvider>
          </AppProvider>
        </CacheProvider>
      </ApolloProvider>
    </OAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
