import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import AccountMenu from './AccountMenu';
import { getUserName } from '../modules/portal';

const PortalButton = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorRef = React.useRef();
  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { i18n } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const user = auth.user;
  const lang = i18n.language || 'de';

  const handleClick = () => {
    if (auth.isAuthenticated) {
      setAnchorEl(anchorRef.current);
    } else {
      auth.signinPopup({
        extraQueryParams: { lang },
      }).then(() => {
        navigate('/portal');
      });
    }
  }

  const iconStyle = !mobile ? {
    color: 'background.lighterblue',
    fontSize: '3rem',
    mb: '0.5rem',
  } : null;

  return <>
    <Button
      aria-label="My Rippon Boswell"
      onClick={handleClick}
      sx={{
        color: 'white',
        display: 'flex',
        flexDirection: mobile ? 'row' : 'column',
        minWidth: mobile ? 0 : 'inherit',
        alignItems: 'center',
      }}
    >
      <AccountCircleIcon
        ref={anchorRef}
        sx={{ ...iconStyle }}
      />
      {!mobile ?
        <Typography sx={{ fontSize: '0.8rem' }}>
          {getUserName(user) || 'My Rippon Boswell'}
        </Typography> : null}
    </Button>
    {user ? (
      <AccountMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    ) : null}
  </>;
};

export default PortalButton;
