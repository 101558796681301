import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import PropTypes from 'prop-types';
import React from 'react';
import { useAuth } from 'react-oidc-context';

const makeToken = (user) => {
  if (user && user.access_token) {
    return `${user.token_type || 'Bearer'} ${user.access_token}`;
  }
  return '';
};

const RipponApolloProvider = ({ children = null }) => {
  const { user } = useAuth();

  const tokenRef = React.useRef(makeToken(user));
  const wsClientRef = React.useRef(null);

  React.useEffect(() => {
    tokenRef.current = makeToken(user);
  }, [user]);

  const client = React.useMemo(() => {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_PORTAL_HTTP,
    });

    wsClientRef.current = createClient({
      url: process.env.REACT_APP_PORTAL_WS,
      connectionParams: () => ({
        authorization: tokenRef.current,
      }),
    });
    const wsLink = new GraphQLWsLink(wsClientRef.current);

    const splitLink = split(({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    }, wsLink, httpLink);

    const tokenLink = () => new ApolloLink((operation, forward) => {
      if (tokenRef.current) {
        operation.setContext(() => ({
          headers: {
            Authorization: tokenRef.current,
          },
        }));
      }
      return forward(operation);
    });

    return new ApolloClient({
      link: ApolloLink.from([tokenLink(), splitLink]),
      cache: new InMemoryCache(),
    });
  }, []);

  React.useEffect(() => {
    if (wsClientRef.current) wsClientRef.current.terminate();
    if (client) client.resetStore();
  }, [client, user?.profile?.sub]);

  return <ApolloProvider client={client}>
    {children}
  </ApolloProvider>;
};

RipponApolloProvider.propTypes = {
  children: PropTypes.node,
};

export default RipponApolloProvider;
